import React, { Fragment } from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Reveal from "react-reveal/Reveal"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import dayjs from "dayjs"

import Layout from "../components/layout/layout"

const AboutPage = props => {
  return (
    <Fragment>
      <Layout>
        <Img
          fadeIn={true}
          className="main-image-wrapper"
          fluid={props.data.aboutImage.childImageSharp.fluid}
        />
        <h1>About</h1>
        <h2>get to know me</h2>
        <p>
          I'm a {`${dayjs().diff("1998-05-15", "year")} `}-year-old Software
          Engineer and passionate technology enthusiast based in Vancouver, BC.
          With first-class honors in Bachelor of Science in Computing,
          specializing in web technologies. Currently, I hold the role of Lead
          Engineer, where I lead and drive technological advancements in my
          field.
        </p>
        <div></div>
        <Reveal duration={750}>
          <section className="skills-container">
            <h3>My Skills</h3>
            <p>
              Through my extensive experience in computer science, software
              development, and visual arts, I have cultivated a profound skill
              set that aligns with industry standards. I have acquired expertise
              in utilizing diverse programming languages, frameworks, libraries,
              and tools to develop high-quality applications at an industry
              level. My proficiency in these areas allows me to excel in
              creating cutting-edge solutions that meet the highest standards of
              quality and performance.
            </p>
            <h4>Programming Languages</h4>
            <table className="skills-list">
              <tr>
                <td>
                  <li>
                    <OutboundLink href="https://www.typescriptlang.org/">
                      TypeScript
                    </OutboundLink>
                  </li>
                </td>
                <td>
                  <li>
                    <OutboundLink href="https://developer.mozilla.org/en-US/docs/Web/JavaScript">
                      JavaScript
                    </OutboundLink>
                  </li>
                </td>
                <td>
                  <li>
                    <OutboundLink href="https://www.python.org/">
                      Python
                    </OutboundLink>
                  </li>
                </td>
              </tr>
              <tr>
                <td>
                  <li>
                    <OutboundLink href="https://www.w3schools.com/html/html_css.asp">
                      HTML/CSS
                    </OutboundLink>
                  </li>
                </td>
                <td>
                  <li>
                    <OutboundLink href="https://developer.apple.com/swift/">
                      Swift
                    </OutboundLink>
                  </li>
                </td>
                <td>
                  <li>
                    <OutboundLink href="https://docs.microsoft.com/en-us/dotnet/csharp/getting-started/">
                      C#
                    </OutboundLink>
                  </li>
                </td>
              </tr>
              <tr>
                <td>
                  <li>
                    <OutboundLink href="https://en.wikipedia.org/wiki/C_(programming_language)">
                      C
                    </OutboundLink>
                  </li>
                </td>
              </tr>
            </table>
            <h4>Frameworks & Libraries</h4>
            <table className="skills-list">
              <tr>
                <td>
                  <li>
                    <OutboundLink href="https://reactjs.org/">
                      React
                    </OutboundLink>
                  </li>
                </td>
                <td>
                  <li>
                    <OutboundLink href="https://reactnative.dev/">
                      React-Native
                    </OutboundLink>
                  </li>
                </td>
                <td>
                  <li>
                    <OutboundLink href="https://nextjs.org/">
                      NextJS
                    </OutboundLink>
                  </li>
                </td>
              </tr>
              <tr>
                <td>
                  <li>
                    <OutboundLink href="https://www.gatsbyjs.org/">
                      GatsbyJS
                    </OutboundLink>
                  </li>
                </td>
                <td>
                  <li>
                    <OutboundLink href="https://nestjs.com/">
                      NestJS
                    </OutboundLink>
                  </li>
                </td>
                <td>
                  <li>
                    <OutboundLink href="https://nodejs.org/">
                      NodeJS
                    </OutboundLink>
                  </li>
                </td>
              </tr>
              <tr>
                <td>
                  <li>
                    <OutboundLink href="https://expressjs.com/">
                      Express
                    </OutboundLink>
                  </li>
                </td>
                <td>
                  <li>
                    <OutboundLink href="https://elm-lang.org/">
                      Elm
                    </OutboundLink>
                  </li>
                </td>
                <td>
                  <li>
                    <OutboundLink href="https://cordova.apache.org/">
                      Cordova
                    </OutboundLink>
                  </li>
                </td>
              </tr>
              <tr>
                <td>
                  <li>
                    <OutboundLink href="http://lesscss.org/">LESS</OutboundLink>
                  </li>
                </td>
                <td>
                  <li>
                    <OutboundLink href="https://sass-lang.com/">
                      SCSS
                    </OutboundLink>
                  </li>
                </td>
                <td>
                  <li>
                    <OutboundLink href="https://sass-lang.com/">
                      SASS
                    </OutboundLink>
                  </li>
                </td>
              </tr>
            </table>

            <h4>DevOps/Development tools</h4>
            <table className="skills-list">
              <tr>
                <td>
                  <li>
                    <OutboundLink href="https://www.mysql.com/">
                      SQL
                    </OutboundLink>
                  </li>
                </td>
                <td>
                  <li>
                    <OutboundLink href="https://www.mongodb.com/">
                      MongoDB
                    </OutboundLink>
                  </li>
                </td>
                <td>
                  <li>
                    <OutboundLink href="https://graphql.org/">
                      GraphQL
                    </OutboundLink>
                  </li>
                </td>
              </tr>
              <tr>
                <td>
                  <li>
                    <OutboundLink href="https://git-scm.com/">Git</OutboundLink>
                  </li>
                </td>
                <td>
                  <li>
                    <OutboundLink href="https://aws.amazon.com/">
                      AWS
                    </OutboundLink>
                  </li>
                </td>
                <td>
                  <li>
                    <OutboundLink href="https://azure.microsoft.com/">
                      Azure
                    </OutboundLink>
                  </li>
                </td>
              </tr>
              <tr>
                <td>
                  <li>
                    <OutboundLink href="https://docs.openshift.com/">
                      RedHat OpenShift
                    </OutboundLink>
                  </li>
                </td>
                <td>
                  <li>
                    <OutboundLink href="https://www.docker.com/">
                      Docker
                    </OutboundLink>
                  </li>
                </td>
              </tr>
            </table>

            <h4>Media tools</h4>
            <table className="skills-list">
              <tr>
                <td>
                  <li>
                    <OutboundLink href="https://www.adobe.com/hk_en/products/xd.html">
                      XD
                    </OutboundLink>
                  </li>
                </td>
                <td>
                  <li>
                    <OutboundLink href="https://www.adobe.com/hk_en/products/illustrator.html">
                      Illustrator
                    </OutboundLink>
                  </li>
                </td>
                <td>
                  <li>
                    <OutboundLink href="https://www.adobe.com/hk_en/products/photoshop.html">
                      Photoshop
                    </OutboundLink>
                  </li>
                </td>
              </tr>
              <tr>
                <td>
                  <li>
                    <OutboundLink href="https://www.adobe.com/hk_en/products/indesign.html">
                      InDesign
                    </OutboundLink>
                  </li>
                </td>
                <td>
                  <li>
                    <OutboundLink href="https://www.adobe.com/hk_en/products/photoshop-lightroom.html">
                      Lightroom
                    </OutboundLink>
                  </li>
                </td>
                <td>
                  <li>
                    <OutboundLink href="https://www.adobe.com/hk_en/products/premiere.html">
                      Premiere Pro
                    </OutboundLink>
                  </li>
                </td>
              </tr>
            </table>
          </section>
        </Reveal>
      </Layout>
    </Fragment>
  )
}

export default AboutPage

export const aboutImage = graphql`
  fragment aboutImage on File {
    childImageSharp {
      fluid(maxWidth: 900) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const data = graphql`
  query {
    aboutImage: file(relativePath: { eq: "media/main/about.jpg" }) {
      ...aboutImage
    }
  }
`
